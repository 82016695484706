const messages = {
    // ====== LABELS ======
    lAppTitle: 'Senior Care',
    lCode:'Codigo',
    lVideollamadaTitle: 'VideoCall',
    lContenidosTitle: 'Records',
    lContenidosAdd: 'Add',
    lContenidosDelete: 'Delete',
    lContenidosEdit: 'Edit Resource',
    lHomeFilterLabel: 'Filter by Home',
    lUserTypeFilterLabel: 'Filter by UserType',
    lUser: 'User',
    lUsersAssigned: 'Assigned Users',
    lUserType: 'User Type',
    lPassword: 'Password',
    lUserRole: 'User grants',
    lUserRoleAssign: 'Asignar Roles del usuario',
    lRequired: 'Required',
    lEmailRequired:'Tipo Email Requerido',
    lSelectDomain: 'Select the domain',
    lLoginTittle: 'Plattform Access',
    lName:'Name',
    lHome:'Homes',
    lImageURL:'Image URL',
    lUploadImage:'Upload Image',
    lImage:'Image',
    lResourceURL:'Resource URL',
    lDescription:'Description',
    lCategory:'Category',
    lSubcategory:'Subcategory',
    lState:'State',
    lCreationUser:'Creation User',
    lSelectHome:'Add home',
    lSelectAllHomes:'Add all Homes',
    lAssignHomes:'Asignar Hogares',
    lSelectUser:'Select User',
    lSeeUser:'See Users',
    lAssignUsersMailPassword:'Send credentials ...',
    lSelect:'Select',
    lSelectStartDate:'Select start date',
    lSelectendDate:'select end date',
    lSelectImagen:'Select Image',
    lImageURLExternal:'Image URL External',
    lFooterOf: 'of',
    lStartDate:'Starts',
    lendDate:'Ends',
    lTenantName:'Tenant',
    lRowsPerPage: 'Rows per page',
    lEnable:'Enable',
    lModerator:'Moderator',
    lRecorder:'Recorder',
    lLiveStreaming:'LiveStreaming',
    lAll: 'All',
    lLoading: 'Loading... Please Wait',
    lActions: 'Actions',
    lFilter: 'Filter',
    lMaxLength:'Maximum size exceded',
    llAphanumericPlus:'Letters or + symbol required',
    lNumericPlus:'Numebers or simbols required',
    lHomesManagement:'Homes management',
    lNewHomes:'New Homes',
    lEditHomes:'Edit Homes',
    lNewUser:'New User',
    lEditUser:'Edit User',
    lUsersManagement:'Users management',
    lThingsboardManagement:'Thingsboard management',
    lYes:'Si',
    lNo:'No',
    lFAMILY:'Familiar',
    lPATIENT:'Usuario',
    lCARER:'Profesional',
    lFriend:'Friend',
    lAdmin:'Admin',
    lNumeric:'Required numeric value',
    // ====== TOOLTIPS ======
    ttDelete: 'Delete',
    ttDeactivate: 'Deactivate',
    ttPermanentDelete: 'Delete Forever',
    ttActivateDeactivate: 'Activate/Deactivate',
    ttEdit: 'Edit',
    ttModify: 'Modify',
    ttPlay:'Play',
    ttSeeUsers:'See Users',
    // ====== BUTTONS ======
    bLogin: 'Login',
    ttOpenDashboard:'Open Dashboard',
    ttOpenDashboardInNewWindows:'Abrir Dashboard en una nueva ventana',
     // ====== Error Messages ======
    eLoginCredentialsInvalid:'Login Wrong',
    eLoginNotActivatedUser:'User not Activate',
    eLoginSesionExpired:'Session expired',
    eLoginUnauthorizedROLE:'Unauthorized Access',
    eNotFilteredResults:'No Results',
    eNotJWT:'Contact list is not available',
    // ====== Others ======
    Family:'Family',
    Carer:'Carer',
    Patient:'Patient',
    All:'All',
    Friend:'Friend',
    // ====== Presence ======
    available:'Available',
    unavailable:'Unavailable',
    busy:'Busy',
    away:'Away',
    // ====== BUTTONS ======
    bAdd: 'Alta',
    bCancel:'Cancel',
    bAccept:'Acept',
    bClose:'Close',
    bSave:'Save',
    bUploadImage:'Upload Image',
    nWakeUpButton: 'Send Push Notification',
    nSendNotification: 'Send Notificacion',
    nReSendNotification: 'Send Again Notificacion',
    // ====== NOTIFICATIONS ======
    nMissedCallTitle: 'Missed Call',
    nMissedCallBody: 'You have a missed call from ',
    nWakeUpTitle: 'INVITE',
    nWakeUpBody: 'APP Wake Up Request',
    // ====== Ejjaberd ======
    lCompleteName:'Complete Name',
    lNickName:'NickName',
    lPhoto:'photo',
    lPrincipalContact:'Principal Contact',
    lContactPhone:'Phone',
    lAge:'Age',
    lAdress:'Address',
    lLocality:'Locality',
    lProvince:'Province',
    lPostalCode:'Postal Code',
    lTelephone:'lTelephone',
    lEmail:'Email',
    lCoordinates:'Coordenadas',

    // ERROR MESSAGES
    '1001':'Session timed out',
    '1002':'Access denied',
    '1003':'Unauthorized role',
    '1004':'User not found',
    '1005':'Null user. Are you logged in?',
    '1006':'Null field entered',
    '1007':'Null tenant, are you logged in correctly',
    '1008':'VCard - Error requesting V-Card',
    '1009':'Rooster - Error requesting Roster',
    '1010':'User types - Error requesting',
    '1011':'Invalid token for notifications',
    '1012':'Error updating notification token',
    '1013':'Error uploading image file',
    '1014':'Error trying to get videos from youtube channel',
    '1015':'Error trying to delete videos from youtube channel',
    '1016':'Null content category',
    '1017':'Contents - Error when performing an operation',
    '1018':'Notification - Error while performing an operation',
    '1019':'Contact does not have firebase token',
    '1020':'Households - Error performing an operation',
    '1021':'Households - Household already exists',
    '1022':'Users - User already exists',
    '1023':'Email - Could not send email',
    '1024':'IOT - Error creating user',
    '1025':'IOT - Error creating Home',
    '1026':'IOT - Error Deleting User from a Home',
    '1027':'An error occurred while permanently deleting a User',
    '1028':'An error has occurred while sending the notification',
    '1029':'Users - A registered user cannot have a null password',
    '1030':'IOT - Error activating or deactivating users',
    '1031':'IOT - Error logging into IOT service',
    '1032':'IOT - Error creating user. There is already a user with this email',
    '1033':'IOT - Error when interacting with the API',
    '1034':'Media Resource - Error Null Content',
    '1035':'Media Resource - Error Empty Name',
    '1036':'Media Resource - Error Empty Description',
    '1037':'Media Resource - Start Date Not Selected',
    '1038':'Media Resource - Start Date cannot be later than End Date',
    '1039': 'IOT - Empty server URL',
    '1040': 'A null JabberId was entered when performing an operation',
    '1041': 'A null ejabberd password was entered when performing an operation',
    '1042': 'The xmpp host field associated with the tenant is null',
    '1043': 'An error response has been received from ejabberd',
    '1044': 'A null group has been entered when performing an operation',
    '1045': 'A null vcard field name has been entered when performing an operation',
    '1046': 'Media Resource - Empty content URL address',
    '1047': 'An error occurred while exchanging information with the IoT server'
}

export default messages
